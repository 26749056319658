import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  BOOKING_STATUS,
  Passenger,
  selectBooking,
  setStatus,
  setPassengers,
} from "./bookingSlice";
import { useForm, useFieldArray, ValidateResult } from "react-hook-form";
import BookingStepsHeader from "./BookingStepsHeader";
import { v4 as uuidv4 } from "uuid";
import intlTelInput from "intl-tel-input";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils.js";

export default function BookingPassengers() {
  const bookingState = useAppSelector(selectBooking);
  const dispatch = useAppDispatch();

  type FormValues = {
    persons: Passenger[];
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    trigger,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      persons: bookingState.passengers,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "persons",
  });

  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState<intlTelInput.Plugin>();

  useEffect(() => {
    let input = document.querySelector("#phone");
    if (!input) return;
    setPhone(
      intlTelInput(input, {
        customContainer: "block mt-2",
        initialCountry: "se",
        preferredCountries: ["se", "no", "dk", "fi"],
        nationalMode: true,
        autoPlaceholder: "off",
        formatOnDisplay: true,
        utilsScript: "/intl-tel-input/js/utils.js",
      })
    );

    const formData = getValues();
    const allFieldsHaveValues = formData.persons.every((person) => {
      return person.firstName && person.lastName;
    }); // Check if all fields within each object have values

    const bookerData =
      formData.persons[0].email &&
      formData.persons[0].phone &&
      /^\+(?:[0-9] ?){6,14}[0-9]$/.test(formData.persons[0].phone);

    if (allFieldsHaveValues && bookerData) {
      trigger();
    }
  }, []);

  const inputStyle =
    "block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring";

  const onSubmit = (data: FormValues) => {
    if (!isValid) {
      return;
    }

    updatePassengers(data.persons);
    dispatch(setStatus(BOOKING_STATUS.PAY));
  };

  const onBlurHandler = async (field: string, i: number) => {
    //@ts-ignore
    await trigger(`persons.${i}.${field}`);
    const formData = getValues();
    updatePassengers(formData.persons);
  };

  const updatePassengers = (passengers: Passenger[]) => {
    const updatedPassengers = passengers.map((passenger) => {
      if (passenger.phone) {
        return {
          ...passenger,
          phone: phone?.getNumber() ?? passenger.phone,
          id: uuidv4(),
        };
      }

      return { ...passenger, id: uuidv4() };
    });

    dispatch(setPassengers(updatedPassengers));
  };

  const validatePhone = (input: string): ValidateResult => {
    return phone?.isValidNumber() ?? /^\+(?:[0-9] ?){6,14}[0-9]$/.test(input);
  };

  const [tooltipVisible, setTooltipVisible] = useState(
    Array(fields.length).fill(false)
  );

  const toggleTooltip = (index: number) => {
    const updatedTooltipVisible = [...tooltipVisible];
    updatedTooltipVisible[index] = !updatedTooltipVisible[index];
    setTooltipVisible(updatedTooltipVisible);
  };

  return (
    <div className="relative w-full py-20 h-auto bg-white">
      <div className="max-w-[1280px] mx-auto px-[8.66%]">
        <BookingStepsHeader status={BOOKING_STATUS.PASSENGER} />

        <h1 className="mt-12 text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">
          {t("passenger", { ns: "common" })}
        </h1>

        <div className="my-6 space-y-8 xl:mt-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <div className="sm:col-span-2 flex items-center justify-between gap-x-2">
                    <p className="text-lg font-bold text-gray-800">
                      {t("passenger", { ns: "common" })} #{index + 1}
                    </p>
                    <p className="px-3 py-1 text-xs text-blue-500 rounded-full bg-blue-100/60">
                      {field.isAdult
                        ? t("adult", { ns: "common" })
                        : t("child", { ns: "common" })}
                    </p>
                  </div>

                  <div>
                    <label
                      htmlFor={`persons[${index}].firstName`}
                      className="text-gray-700 hover:cursor-pointer"
                    >
                      {t("firstname", { ns: "common" })}
                    </label>
                    <input
                      type="text"
                      {...register(`persons.${index}.firstName`, {
                        required: true,
                        pattern: /^[a-zA-ZåäöÅÄÖÆØüßÜ \-]+$/,
                        maxLength: 64,
                      })}
                      className={`${inputStyle} ${
                        errors.persons?.[index]?.firstName && "border-red-500"
                      }`}
                      onBlur={() => onBlurHandler("firstName", index)}
                    />
                    {errors.persons?.[index]?.firstName?.type == "required" && (
                      <p className="text-red-500 text-xs mt-1 italic">
                        {t("valid.firstname_req", { ns: "common" })}
                      </p>
                    )}

                    {errors.persons?.[index]?.firstName?.type == "pattern" && (
                      <p className="text-red-500 text-xs mt-1 italic">
                        {t("valid.firstname_invalid", { ns: "common" })}
                      </p>
                    )}

                    {errors.persons?.[index]?.firstName?.type ==
                      "maxLength" && (
                      <p className="text-red-500 text-xs mt-1 italic">
                        {t("valid.firstname_invalid2", { ns: "common" })}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor={`persons[${index}].lastName`}
                      className="text-gray-700 hover:cursor-pointer"
                    >
                      {t("surname", { ns: "common" })}
                    </label>
                    <input
                      type="text"
                      {...register(`persons.${index}.lastName`, {
                        required: true,
                        pattern: /^[a-zA-ZåäöÅÄÖÆØüßÜ \-]+$/,
                        maxLength: 64,
                      })}
                      className={`${inputStyle} ${
                        errors.persons?.[index]?.lastName && "border-red-500"
                      }`}
                      onBlur={() => onBlurHandler("lastName", index)}
                    />
                    {errors.persons?.[index]?.lastName?.type == "required" && (
                      <p className="text-red-500 text-xs mt-1 italic">
                        {t("valid.surename_req", { ns: "common" })}
                      </p>
                    )}

                    {errors.persons?.[index]?.lastName?.type == "pattern" && (
                      <p className="text-red-500 text-xs mt-1 italic">
                        {t("valid.lastname_invalid", { ns: "common" })}
                      </p>
                    )}

                    {errors.persons?.[index]?.lastName?.type ==
                      "maxLength" && (
                      <p className="text-red-500 text-xs mt-1 italic">
                        {t("valid.lastname_invalid2", { ns: "common" })}
                      </p>
                    )}
                  </div>

                  {field.isBillingPassenger && (
                    <>
                      <div>
                        <label
                          htmlFor="email"
                          className="text-gray-700 hover:cursor-pointer"
                        >
                          {t("email", { ns: "common" })}
                        </label>
                        <input
                          type="email"
                          id="email"
                          {...register(`persons.${index}.email`, {
                            required: true,
                            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          })}
                          className={`${inputStyle} ${
                            errors.persons?.[index]?.email && "border-red-500"
                          }`}
                          onBlur={() => onBlurHandler("email", index)}
                        />
                        {errors.persons?.[index]?.email?.type ===
                          "required" && (
                          <p className="text-red-500 text-xs mt-1 italic">
                            {t("valid.email_req", { ns: "common" })}
                          </p>
                        )}
                        {errors.persons?.[index]?.email?.type === "pattern" && (
                          <p className="text-red-500 text-xs mt-1 italic">
                            {t("valid.email_err", { ns: "common" })}
                          </p>
                        )}
                      </div>

                      <div>
                        <label
                          htmlFor="phone"
                          className="text-gray-700 hover:cursor-pointer"
                        >
                          {t("phone", { ns: "common" })}
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          {...register(`persons.${index}.phone`, {
                            required: true,
                            validate: { validatePhone },
                          })}
                          className={`${inputStyle} ${
                            errors.persons?.[index]?.phone && "border-red-500"
                          }`}
                          onBlur={() => onBlurHandler("phone", index)}
                          autoComplete="tel"
                        />
                        {errors.persons?.[index]?.phone?.type ===
                          "required" && (
                          <p className="text-red-500 text-xs mt-1 italic">
                            {t("valid.phone_req", { ns: "common" })}
                          </p>
                        )}
                        {errors.persons?.[index]?.phone?.type ===
                          "validatePhone" && (
                          <p className="text-red-500 text-xs mt-1 italic">
                            {t("valid.phone_err", { ns: "common" })}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="flex items-center space-x-2 mt-5">
                  <input
                    type="checkbox"
                    {...register(`persons.${index}.infoToDriver`)}
                    defaultChecked={true}
                    id={`persons[${index}].largeLuggage`}
                    className="h-5 w-5 text-blue-500"
                  />
                  <label
                    htmlFor={`persons[${index}].largeLuggage`}
                    className="text-gray-700 hover:cursor-pointer relative"
                  >
                    {t("large_luggage.checkbox", { ns: "translation" })}
                  </label>
                  <InformationCircleIcon
                    className={`ml-auto h-5 w-5 text-blue-300 cursor-pointer`}
                    onClick={() => toggleTooltip(index)}
                  />
                </div>

                <div
                  className={`${
                    tooltipVisible[index] ? "visible" : "hidden"
                  }  bg-blue-300 text-gray-800 py-1 px-3 mt-2 rounded-sm text-sm top-full transform  transition-opacity duration-400`}
                >
                  {t("large_luggage.tooltip", { ns: "translation" })}
                </div>

                {index !== fields.length - 1 && (
                  <hr className="my-10 border-gray-100" />
                )}
              </div>
            ))}

            <div className="w-full flex flex-col-reverse items-center gap-y-6 mt-6 lg:flex-row lg:justify-between">
              <button
                type="button"
                onClick={() => {
                  dispatch(setStatus(BOOKING_STATUS.AVAILABILITY));
                }}
                className={`w-full py-4 px-12 text-white font-bold bg-gray-500 border-1 uppercase lg:w-auto`}
              >
                {t("back", { ns: "common" })}
              </button>
              <button
                type="submit"
                disabled={!isValid}
                className={`w-full py-4 px-12 text-white font-bold bg-ft-blue-dark border-1 uppercase lg:w-auto ${
                  !isValid ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {t("continue", { ns: "common" })}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
